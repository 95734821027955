<template>
  <div class="app-page packages-page">
    <Navbar />
    <div class="main-wrapper">
      <Packs :packages="packages" />
    </div>
  </div>
</template>

<script>
import axios from "axios"
import Navbar from "../components/header/Navbar.vue";
import Packs from "../components/packages/Packs.vue";

export default {
  name: "Packages",
  components: {
    Navbar,
    Packs,
  },
  data: function () {
    return {
      errorMessage: null,
      packages: [],
      showLoader: 0,
    };
  },
  methods: {
    getData() {
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}package/listing`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.packages = response.data.data;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },
  mounted() {
      this.getData();

    // localStorage.getItem("ListlastPage");
  },
};
</script>

<style></style>
